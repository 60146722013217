import { useEffect, useRef, useState } from 'react';
import { SSE_ENDPOINT } from '~/config';
import { firebaseGetToken } from '~/services/firebase';
import { memoryStorage } from '~/utils/localStorage';

const TIMEOUT_DURATION = 2 * 60 * 1000;

const useCheckRefreshTask = () => {
  const [hasUpdate, setHasUpdate] = useState(true);
  const [hasConnected, setHasConnected] = useState(false);
  const reconnectAttemptsRef = useRef(0);
  const eventSourceRef = useRef(null);
  const timeoutRef = useRef(null);
  const timeoutReconnectRef = useRef(null);

  const createEventSource = async () => {
    const token = await firebaseGetToken();
    const currentOrgId = memoryStorage.getOrgId();

    const eventSource = new EventSource(
      `${SSE_ENDPOINT}/sse/tasks/?token=${token}&canfleet-org-id=${currentOrgId}`,
    );

    const handleReconnection = () => {
      clearTimeout(timeoutRef.current);
      clearTimeout(timeoutReconnectRef.current);
      const delay = Math.min(
        5 * 1000 * reconnectAttemptsRef.current,
        TIMEOUT_DURATION,
      );
      timeoutReconnectRef.current = setTimeout(() => {
        reconnectAttemptsRef.current += 1;
        createEventSource();
      }, delay);
    };

    timeoutRef.current = setTimeout(() => {
      console.log('Connection timed out. Reconnecting...');

      eventSource.close();
      handleReconnection();
    }, TIMEOUT_DURATION);

    eventSource.onopen = (event) => {
      console.log('Connected to server:', event);

      reconnectAttemptsRef.current = 0;
      setHasConnected(true);
      clearTimeout(timeoutRef.current);
    };

    eventSource.onmessage = (event) => {
      console.log('On update', event);

      const isRefresh = JSON.parse(event?.data);
      if (isRefresh) {
        setHasUpdate(true);
      }
    };

    eventSource.onerror = (event) => {
      console.log('Connection failed. Attempting to reconnect...', event);

      setHasConnected(false);
      eventSource.close();
      handleReconnection();
    };

    eventSourceRef.current = eventSource;
  };

  const resetUpdate = () => {
    setHasUpdate(false);
  };

  const forceUpdate = () => {
    setHasUpdate(true);
  };

  useEffect(() => {
    createEventSource();

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current?.close();
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return {
    hasUpdate,
    resetUpdate,
    hasConnected,
    forceUpdate,
  };
};

export default useCheckRefreshTask;
